.viaBank {
    padding: 0 60px;
    flex-grow: 1;
  }
  .viaBank__title {
    /* color: #8773b9; */
    margin-bottom: 30px;
  }
  .viaBank input {
    border: none;
  }
  /* .viaBank input::placeholder {
    color: #9f8ec7;
  } */
.collection__container {
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 2.5rem;
}
.collection__header {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-color);
}
.header__name {
  width: 50%;
}
.header__amount {
  width: 25%;
}
.header__action {
  width:25%;
}
.collection__content {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.content__name {
  width: 50%;
}
.content__amount {
  width: 25%;
}
.content__action {
  width:25%;
}
.edit {
  padding: 2px 6px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  border-radius: 3px;
  font-size: 0.7rem;
}
.delete {
  padding: 2px 6px;
  color: white;
  border: 1px solid red;
  background-color: red;
  outline: none;
  border-radius: 3px;
  font-size: 0.7rem;
  margin-left: 6px;
}