.viaWallet {
    padding: 0 60px;
    flex-grow: 1;
  }
  .viaWallet__title {
    /* color: #8773b9; */
    margin-bottom: 30px;
  }
  .viaWallet input {
    border: none;
  }
  /* .viaWallet input::placeholder {
    color: #9f8ec7;
  } */
  