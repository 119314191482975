.customerDetail__fullName {
	font-weight: bold;
	margin-bottom: 0;
}

.customerDetail__email {
	font-size: 10px;
	color: #737373;
	margin-bottom: 0;
}

.table__container {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: gray;
    margin-bottom: 2rem;
}

.link__container {
    text-decoration: none;
}

.image__link {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 5px;
}