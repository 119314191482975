.navbar {
    top: 0;
    z-index: 2;
    position: sticky;
    background-color: #fff;
}

.navbar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.navbar__logo {
    width: 110px;
    height:4.5rem;
    object-fit: contain;
}

.navbar__initials {
    height: 40px;
    width: 40px;
    background-color: var(--background-color);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-radius: 20px;
}

.navbar__username {
    font-weight: bold;
    margin: 0;
    color: #3f536e;
}

.navbar__rightContent {
    /* width: 180px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    position: relative;
}

.navbar__chevron {
    color: #3f536e;
    font-size: 11px;
}

.merchant__container {
    right: 0;
    display: none;
    width: 250px;
    z-index: 10;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    margin: -10px 20px 0 auto;
}

.merchant__text {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50px;
    font-size: 90%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fafafa;
}

.remove_merchant__text {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50px;
    font-size: 90%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: rgb(199, 0, 0);
    border-bottom: 1px solid #fafafa;
}

.remove_merchant__text:hover {
    text-decoration: underline;
}

.down__arrow {
    width: 15px;
    height: 15px;
}

.icon__container {
    padding: 5px;
    cursor: pointer;
    margin-left: 15px;
}
