.sidenav {
  position: sticky;
  top: 50px;
  left: -130px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px 25px;
}

.sidenav__navItem,
.sidenav__logoutContainer {
  display: flex;
  align-items: center;
  min-height: 42px;
  width: 180px;
  padding-left: 10px;
  margin-bottom: 15px;
}

.sidenav__navItem span,
.sidenav__logoutContainer span {
  color:#384C55;
  margin-left: 10px;
  text-decoration: none;
}
.sidenav__navItemActive span {
  color:#384C55 !important;
  font-weight: bold;
}

.sidenav__navItemActive {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.sidenav a {
  text-decoration: none;
}
/* .sidenav__logoutContainer {
  color: rgba(44, 32, 103, 0.4);
} */
.sidenav__logoutContainer {
  cursor: pointer;
}
