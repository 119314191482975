.customerDetail__fullName {
	font-weight: bold;
	margin-bottom: 0;
}

.customerDetail__email {
	font-size: 10px;
	color: #737373;
	margin-bottom: 0;
}
