.nav__item {
	color: var(--primary-color) !important;
	margin-right: 20px;
	text-decoration: none !important;
}
.nav__itemActive {
	color: #fff !important;
	background-color: #87BD40;
	padding: 10px 20px;
	border-radius: 20px;
	font-weight: bold;
}

.active__link__nav {
	color: #fff !important;
	background-color: #87BD40;
	padding: 10px 20px;
	border-radius: 20px;
	font-weight: bold;
	margin-right: 20px;
	text-decoration: none !important;
}
