.bankDetails__accountInfo {
	background-color: #87BD40;
	padding: 5px 15px;
	border-radius: 5px;
}
.bankDetails__accountTitle {
	color: #ffffff;
	font-size: 11px;
	margin-bottom: 0;
	font-weight: 300;
}
.bankDetails__accountValue {
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
}
