.tableContainer {
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
}
.goToMerchantText {
  color: #737373;
  font-size: 0.9em;
}

.search__container {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.search__content {
  flex: 1;
}
.search__content input {
  outline: none;
}

.export {
  margin: -8px 0 0 0 !important;
}

.filter__container {
  position: relative;
  z-index: 1100;
  width: 15rem;
  border-radius: 5px;
  background-color: #fff;
  margin-top: -25px;
  border: 1px solid #f5f5f5;
  margin-left: auto;
}

.filter__top {
  display: flex;
  height: 3rem;
  width: 100%;
  background-color: #f5f5f5;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.filter__top p {
  padding: 0;
  margin: 0;
  font-size: 95%;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.filter__top button {
  font-size: 85%;
  border: 0;
  outline: 0;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  background-color: #2379BE;
  color: #fff;
}

.filter__bottom {
  padding: 0 0.5rem 1rem 0.5rem;
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 12000;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
}

.filter__bottom div {
  padding: 0;
  margin: 0;
}

.filter__bottom p {
  padding: 0;
  margin: 0;
  margin-top: 0.8rem;
  font-size: 90%;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
}

.filter__bottom select {
  width: 100%;
  height: 2.5rem;
  outline: 0;
  border: 2px solid #f5f5f5;
  border-radius: 3px;
  font-size: 90%;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.7);
}

.date__container {
  width: 100%;
  height: 2.5rem;
  color: gray;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  padding: 2px 4px;
}

.export__container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.7rem;
}

.popup__hover {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
