@font-face {
	font-family: "BwGradual-Bold";
	src: local("BwGradual-Bold"), url("./assets/fonts/BwGradual-Bold.otf");
}

h1,
h2 {
	font-family: BwGradual-Bold, sans-serif;
}

:root {
	--background-color: #f5f5f5;
	--primary-color:  #2379BE;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--background-color) !important;
}
* {
	font-family: "DM Sans", sans-serif;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: var(--background-color) !important;
}

.settings__lightText {
	font-weight: 300;
	font-size: 12px;
	margin: 0;
}
.page-item.active .page-link {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
	color: #fff;
}
.page-item.active .page-link {
	color: #fff !important;
}
.page-link {
	color: var(--primary-color) !important;
}
.initials {
	height: 35px;
	width: 35px;
	background-color: #2946ae;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	border-radius: 17.5px;
}
.btn-warning-sec {
	color:#fff !important;
	background-color: #87BD40 !important;
  }

@media (min-width: 1500px) {
	.container {
		max-width: 1600px !important;
		/* width: 100% !important;
    padding: 0 5% !important; */
	}
}
