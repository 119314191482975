.customerDetail__fullName {
  font-weight: bold;
  margin-bottom: 0;
}

.customerDetail__email {
  font-size: 10px;
  color: #737373;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header p {
  color: black;
  opacity: 80%;
  font-size: 1.3rem;
  font-weight: 600;
}

.header img {
  width: 1.5rem;
  height: 1.5rem;
}

.request__header {
  color: black;
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;
}
