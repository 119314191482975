.tableContainer {
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
}
.goToMerchantText {
	color: #737373;
	font-size: 0.9em;
}

.row__style {
	cursor: pointer;
}

.row__style:hover {
	background-color: rgba(0,0,0,0.1);
}
