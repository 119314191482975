.wallet {
  margin-bottom: 2.5rem;
}
.wallet p {
  margin: 0;
}

.wallet__content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.wallet__amountTitle {
  font-weight: lighter;
  color: #4a4a4a;
  /* margin-bottom: 0; */
}
.wallet__amount {
  font-weight: bold;
  color: #4a4a4a;
}
.wallet__vl {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  /* height: 100%; */
}
.wallet__hl {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 3px 0;
}
.wallet__headerIcon {
  cursor: pointer;
}
