.primaryBtn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.outlineBtn {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.redBtn {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}
