.customers__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.customers__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.customers__totalAmount {
  color: #4a4a4a;
  text-align: end;
}

.animation {
  transition: all 0.3s ease-in-out;
}

.switch {
  border-radius: 30rem;
  padding: 3px;
  cursor: pointer;
  width: 3rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

.bulb {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 100%;
  background-color: white;
}

.switchedOn {
  background-color: #b57214;
  justify-content: end;
}

.switchedOff {
  background-color: rgb(171, 167, 167);
  justify-content: start;
}