.customers__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.customers__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.customers__totalAmount {
  color: #4a4a4a;
  text-align: end;
}

.actionBtn {
  border: none;
  background-color: transparent;
}

.load__cash {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
