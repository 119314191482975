.transactions__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.transactions__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.transactions__totalAmount {
  color: #4a4a4a;
  text-align: end;
}
.descriptionRow {
  width: 50%;
}

.filter__container {
  margin-left: 5rem;
  height: 2.3rem;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
  z-index: 2;
}

.filter__placeholder {
  width: 12rem;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
}

.filter__icon {
  width: 12px;
  height: 12px;
}

.filter__content {
  width: 15rem;
  border: 1px solid lightgray;
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: 3rem;
  background-color: #fff;
}

.filter__content__top {
  width: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.filter__top__text {
  color: gray;
  font-size: 95%;
  padding: 0;
  margin: 0;
}

.filter__button {
  background-color: green;
  color: white;
  padding: 5px 15px;
  outline: none;
  border: none;
  border-radius: 3px;
  font-size: 90%;
}

.filter__content__bottom {
  padding: 10px;
}

.filter__select {
  width: 100%;
  height: 2.5rem;
  color: gray;
  padding: 2px 4px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
}

.filter__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.btn__container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date__container {
  width: 100%;
  height: 2.5rem;
  color: gray;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  padding: 2px 4px;
}

.btn-warning-sec-sec {
  color:#fff;
  background-color: #87BD40 !important;
}
