.partner__content {
    padding: 25px 30px;
    border-radius: 8px;
    margin-top: 3rem;
    background-color: #fff;
}

.partner__totalText {
    margin: 0;
    font-weight: 200;
    color: #7a7a7a;
}
.partner__totalAmount {
    color: #4a4a4a;
    text-align: end;
}

.popup__hover {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

.filter__container {
    position: relative;
	z-index: 1100;
	width: 15rem;
	border-radius: 5px;
	background-color: #fff;
	margin-top: -15px;
	border: 1px solid #f5f5f5;
	margin-left: auto;
}

.filter__top {
    display: flex;
	height: 3rem;
	width: 100%;
	background-color: #f5f5f5;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	position: absolute;
	top: 0;
	left: 0;
}

.filter__top p {
    padding: 0;
    margin: 0;
    font-size: 95%;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
}

.filter__top button {
    font-size: 85%;
    border: 0;
    outline: 0;
    border-radius: 3px;
    padding: 0.3rem 1rem;
    background-color: #2379BE;
    color: #fff;
}

.filter__bottom {
    padding: 0 0.5rem 1rem 0.5rem;
	position: absolute;
	top: 3rem;
	right: 0;
	z-index: 12000;
	background-color: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 3px;
}

.filter__bottom div {
    padding: 0;
    margin: 0;
}

.filter__bottom p {
    padding: 0;
    margin: 0;
    margin-top: 0.8rem;
    font-size: 90%;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.8);
}

.filter__bottom select {
    width: 100%;
    height: 2.5rem;
    outline: 0;
    border: 2px solid #f5f5f5;
    border-radius: 3px;
    font-size: 90%;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
}

.wallet__content {
    background-color: #fff;
    padding: 15px 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}
.transaction__balanceSheet {
    width: 40%;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #87BD40;
}
.transaction__balanceSheetTitle {
    padding-bottom: 3px;
    font-size: 1.2;
    margin-top: 10px;
    color: #ffffff;
    font-weight: normal;
    border-bottom: 1px solid #ffffff;
}
.transaction__balanceSheetAmount {
    margin-top: 10px;
    color: #ffffff;
    font-weight: bold;
}
.transaction__section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}
.wallet__amountTitle {
    font-weight: lighter;
    color: #7a7a7a;
}
.wallet__amount {
    font-weight: bold;
    color: #4a4a4a;
    font-size: 1.2rem;
    margin-top: 5px;
}
.wallet__vl {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    /* height: 100%; */
}
.wallet__hl {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 3px 0;
}
.wallet__headerIcon {
    cursor: pointer;
}

.customerDetail__fullName {
    font-weight: bold;
    margin-bottom: 0;
}

.customerDetail__email {
    font-size: 10px;
    color: #737373;
}

.totalText {
    margin: 0;
    font-weight: 200;
    color: #7a7a7a;
}
.totalAmount {
    color: #4a4a4a;
    text-align: end;
}
