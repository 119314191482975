.customers__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.customers__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.customers__totalAmount {
  color: #4a4a4a;
  text-align: end;
}

.cash_load_form_container {
  width: 100%;
}

.cash_load_container {
  margin: 5rem auto 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  width: 30rem;
}
