.wallet {
	margin-bottom: 2.5rem;
}
.wallet p {
	margin: 0;
}

.wallet__content {
	background-color: #fff;
	padding: 15px 30px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
}
.transaction__balanceSheet {
	width: 40%;
	padding: 10px 20px;
	border-radius: 5px;
	background-color: #87BD40;
}
.transaction__balanceSheetTitle {
	padding-bottom: 3px;
	font-size: 1.2;
	margin-top: 10px;
	color: #ffffff;
	font-weight: normal;
	border-bottom: 1px solid #ffffff;
}
.transaction__balanceSheetAmount {
	margin-top: 10px;
	color: #ffffff;
	font-weight: bold;
}
.transaction__section {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
}
.wallet__amountTitle {
	font-weight: lighter;
	color: #7a7a7a;
}
.wallet__amount {
	font-weight: bold;
	color: #4a4a4a;
	font-size: 1.2rem;
	margin-top: 5px;
}
.wallet__vl {
	width: 1px;
	background-color: rgba(0, 0, 0, 0.1);
	/* height: 100%; */
}
.wallet__hl {
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.1);
	margin: 3px 0;
}
.wallet__headerIcon {
	cursor: pointer;
}

.customerDetail__fullName {
	font-weight: bold;
	margin-bottom: 0;
}

.customerDetail__email {
	font-size: 10px;
	color: #737373;
}

.totalText {
	margin: 0;
	font-weight: 200;
	color: #7a7a7a;
}
.totalAmount {
	color: #4a4a4a;
	text-align: end;
}
