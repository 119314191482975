.tableContainer {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
}
.goToMerchantText {
    color: #737373;
    font-size: 0.9em;
}
.actionBtn {
    border: none;
    background-color: transparent;
}
