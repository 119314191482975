.customers__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.customers__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.customers__totalAmount {
  color: #4a4a4a;
  text-align: end;
}

.search__container {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.search__content {
  flex: 1;
}
.search__content input {
  outline: none;
}

.export {
  margin: -8px 0 0 0 !important;
}

.search__category {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.category {
  margin-top: -8px;
  width: 12rem;
  border: 1px solid #ced4d9;
  border-radius: 5px;
  height: 2.35rem;
  background-color: white;
  color: #485056;
  outline: none;
  padding: 3px 10px;
}

.create__card {
  margin-top: 0 !important;
  color: var(--primary-color);
  border: 1.2px solid var(--primary-color);
  border-radius: 3px;
  padding: 5px 15px;
  cursor: pointer;
}
