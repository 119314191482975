.partner__content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
}

.partner__totalText {
  margin: 0;
  font-weight: 200;
  color: #7a7a7a;
}
.partner__totalAmount {
  color: #4a4a4a;
  text-align: end;
}

.filter__container {
  position: absolute;
  right: 9.2rem;
  z-index: 1000;
  width: 12rem;
  border-radius: 5px;
  background-color: #fff;
  margin-top: -15px;
  border: 1px solid #F5F5F5;
}

@media only screen and (min-width: 1350px) and (max-width: 1400px) {
  .filter__container {
    right: 9.5rem;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1499px) {
  .filter__container {
    right: 13rem;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1799px) {
  .filter__container {
    right: 9rem;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2000px) {
  .filter__container {
    right: 14.5rem;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2500px) {
  .filter__container {
    right: 30rem;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 3000px) {
  .filter__container {
    right: 35rem;
  }
}

.filter__top {
  display: flex;
  height: 3rem;
  background-color: #F5F5F5;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
}

.filter__top p {
  padding: 0;
  margin: 0;
  font-size: 95%;
  font-weight: 600;
  color: rgba(0,0,0,.7);
}

.filter__top button {
  font-size: 85%;
  border: 0;
  outline: 0;
  border-radius: 3px;
  padding: .3rem 1rem;
  background-color: #2379BE;
  color: #fff;
}

.filter__bottom {
  padding: 0 .5rem 1rem .5rem;
}

.filter__bottom div {
  padding: 0;
  margin: 0;
}

.filter__bottom p {
  padding: 0;
  margin: 0;
  margin-top: .8rem;
  font-size: 90%;
  font-weight: 300;
  color: rgba(0,0,0,.8);
}

.filter__bottom select {
  width: 100%;
  height: 2rem;
  outline: 0;
  border: 2px solid #F5F5F5;
  border-radius: 3px;
  font-size: 90%;
  font-weight: 300;
  color: rgba(0,0,0,.7);
}

