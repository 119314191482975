.table__thead {
  color: var(--primary-color);
  font-size: 13px;
  border: none;
  color: #384c55;
}
.table__thead th {
  text-transform: uppercase;
  color: #384c55;
}

.table__thead tr {
  color: #384c55;
}
