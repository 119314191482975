.sideContent {
  right: 0;
  top: 0;
  position: fixed;
  width: 400px;
  background-color: #fff;
  margin-left: auto;
  height: 100%;
  padding: 40px 30px;
  z-index: 7;
  overflow-y: auto;
}
.noDisplay {
  display: none;
}
