.transactions__content {
	background-color: #fff;
	padding: 25px 30px;
	border-radius: 8px;
}

.transactions__totalText {
	margin: 0;
	font-weight: 200;
	color: #7a7a7a;
}
.transactions__totalAmount {
	color: #4a4a4a;
	text-align: end;
}
.descriptionRow {
	width: 50%;
}

.container {
	padding: 5rem 5rem 3rem 5rem;
}