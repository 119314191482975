.customerDetail__fullName {
  font-weight: bold;
  margin-bottom: 0;
}

.customerDetail__email {
  font-size: 10px;
  color: #737373;
}

.reason__container {
  width: 100%;
}

.reason__container h2 {
  font-size: 1.2rem;
  color: black;
}

.form__container {
  width: 100%;
  margin-top: 1.5rem;
}

.input {
  width: 100%;
  height: 10rem;
  padding: 12px;
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  text-align: left;
  vertical-align: top;
  font-size: 1rem;
}

.submit {
  width: 100%;
  text-align: center;
  padding: 10px 10px !important;
}

.error {
  color: red;
  font-size: 0.9rem;
}

.btn__container {
  margin-top: 1.5rem;
}
