.customerDetail__fullName {
    font-weight: bold;
    margin-bottom: 0;
}

.customerDetail__email {
    font-size: 10px;
    color: #737373;
}

.tableContainer {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
}
