.container {
    width: 100%;
}

.formContainer {
    width: 100%;
}

.selectContainer {
    width: 100%;
    margin-bottom: 2rem;
}

.select input {
    height: 2.5rem;
}

.selectError input {
    height: 2.5rem;
    
}

.selectError:first-child {
    border: 2px solid red;
    border-radius: 6px;
    overflow: hidden;
}

.error {
    margin-top: 3px;
    margin-left: 5px;
    font-size: 0.9rem;
    color: red;
}