.navbar {
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 2;
}

.navbar__content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
}

.main__header__content {
	padding-left: 1.5rem;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.navbar__logo {
	width: 110px;
	height: 4.5rem;
	object-fit: contain;
}

.navbar__initials {
	height: 40px;
	width: 40px;
	background-color: var(--background-color);
	color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	border-radius: 20px;
}

.navbar__initials__new {
	height: 30px;
	width: 30px;
	background-color: var(--background-color);
	color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	border-radius: 15px;
}

.navbar__username {
	font-weight: bold;
	margin: 0;
	color: #3f536e;
}

.navbar__username__new {
	font-weight: bold;
	margin: 0;
	color: #3f536e;
	flex-grow: 1;
	font-size: 80%;
}

.navbar__rightContent {
	/* width: 180px; */
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	position: relative;
	cursor: pointer;
}

.navbar__rightContent__new {
	/* width: 180px; */
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	position: relative;
	cursor: pointer;
}

.navbar__chevron {
	color: #3f536e;
	font-size: 11px;
}

.profile__container {
	position: relative;
	z-index: 20;
	width: 15rem;
}

.merchant__list__container {
	position: absolute;
	left: 0;
	top: 55px;
	width: 100%;
	max-height: 80vh;
	overflow-y: auto;
	background-color: white;
	z-index: 21;
	border: 1px solid whitesmoke;
	border-radius: 4px;
}

.merchant__list {
	width: 100%;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	position: relative;
	z-index: 25;
	display: flex;
	align-items: center;
	padding-left: 20px;
	font-size: 80%;
}

.merchant__list:hover {
	background-color: rgb(238, 238, 238);
}

.merchant__overlay {
	width: 100%;
	height: 100vh;
	background-color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
}
