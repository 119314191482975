.viaWallet {
  padding: 0 60px;
  flex-grow: 1;
}
.viaWallet__title {
  /* color: #8773b9; */
  margin-bottom: 30px;
}
.viaWallet input {
  border: none;
}
/* .viaWallet input::placeholder {
    color: #9f8ec7;
  } */
.collection__container {
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 2.5rem;
}
.collection__header {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-color);
}
.header__name {
  width: 50%;
}
.header__amount {
  width: 25%;
}
.header__action {
  width: 25%;
}
.collection__content {
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
}
.content__name {
  width: 50%;
  word-wrap: break-word;
  padding-right: 10px;
}
.content__amount {
  width: 25%;
}
.content__action {
  width: 25%;
}
.edit {
  padding: 2px 6px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  border-radius: 3px;
  font-size: 0.7rem;
  cursor: pointer;
}
.delete {
  padding: 2px 6px;
  color: white;
  border: 1px solid red;
  background-color: red;
  outline: none;
  border-radius: 3px;
  font-size: 0.7rem;
  margin-left: 6px;
  cursor: pointer;
}

.trash {
  width: 0.7rem;
  height: 0.7rem;
}

.csv__file__input {
  display: none;
}

.csv__file__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #384c55;
  border-radius: 5px;
  padding: 1rem 2rem;
  border-style: dashed;
  width: 100%;
  min-height: 10rem;
  cursor: pointer;
}

.csv__file__text {
  color: #384c55;
  font-size: 1.1rem;
  text-align: center;
  width: 70%;
  font-weight: 400;
}

.desc__input {
  margin-top: 2rem;
  border: 1px solid #384c55;
}

.content__wrapper {
  width: 100%;
  padding-left: 2rem;
}

.top__btn {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.empty {
  width: 100%;
  margin-top: 4rem;
  font-size: 1.2rem;
  text-align: center;
}
