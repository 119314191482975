.tableContainer {
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
}
.goToMerchantText {
	color: #737373;
	font-size: 0.9em;
}

.partner__content {
	background-color: #fff;
	padding: 25px 30px;
	border-radius: 8px;
}

.partner__totalText {
	margin: 0;
	font-weight: 200;
	color: #7a7a7a;
}

.partner__totalAmount {
	color: #4a4a4a;
	text-align: end;
}

.popup__hover {
	position: absolute;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
  }

.filter__container {
	position: relative;
	z-index: 1100;
	width: 15rem;
	border-radius: 5px;
	background-color: #fff;
	margin-top: -15px;
	border: 1px solid #f5f5f5;
	margin-left: auto;
}

/* @media only screen and (min-width: 1350px) and (max-width: 1400px) {
	.filter__container {
		right: 9.5rem;
	}
}

@media only screen and (min-width: 1401px) and (max-width: 1499px) {
	.filter__container {
		right: 13rem;
	}
}

@media only screen and (min-width: 1500px) and (max-width: 1799px) {
	.filter__container {
		right: 9rem;
	}
}

@media only screen and (min-width: 1800px) and (max-width: 2000px) {
	.filter__container {
		right: 14.5rem;
	}
}

@media only screen and (min-width: 2001px) and (max-width: 2500px) {
	.filter__container {
		right: 30rem;
	}
}

@media only screen and (min-width: 2501px) and (max-width: 3000px) {
	.filter__container {
		right: 35rem;
	}
} */

.filter__top {
	display: flex;
	height: 3rem;
	width: 100%;
	background-color: #f5f5f5;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	position: absolute;
	top: 0;
	left: 0;
}

.filter__top p {
	padding: 0;
	margin: 0;
	font-size: 95%;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.7);
}

.filter__top button {
	font-size: 85%;
	border: 0;
	outline: 0;
	border-radius: 3px;
	padding: 0.3rem 1rem;
	background-color: #2379BE;
	color: #fff;
}

.filter__bottom {
	padding: 0 0.5rem 1rem 0.5rem;
	position: absolute;
	top: 3rem;
	right: 0;
	z-index: 12000;
	background-color: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 3px;
}

.filter__bottom div {
	padding: 0;
	margin: 0;
}

.filter__bottom p {
	padding: 0;
	margin: 0;
	margin-top: 0.8rem;
	font-size: 90%;
	font-weight: 300;
	color: rgba(0, 0, 0, 0.8);
}

.filter__bottom select {
	width: 100%;
	height: 2.5rem;
	outline: 0;
	border: 2px solid #f5f5f5;
	border-radius: 3px;
	font-size: 90%;
	font-weight: 300;
	color: rgba(0, 0, 0, 0.7);
}