.sideContent {
  right: 0;
  top: 0;
  position: fixed;
  width: 400px;
  background-color: #fff;
  margin-left: auto;
  height: 100%;
  padding: 40px 30px;
  z-index: 7;
  overflow-y: auto;
}
.noDisplay {
  display: none;
}

.sideContentNew {
  right: 0;
  top: 0;
  position: fixed;
  width: 400px;
  background-color: transparent;
  margin-left: auto;
  height: 100%;
  padding: 40px 30px;
  z-index: 7;
  overflow-y: auto;
}

.centralized {
  right: 0;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px 30px;
  z-index: 7;
}
